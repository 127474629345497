.quote-source {
  width: 455px;
  margin-right: 30px;
  transition: box-shadow 175ms ease;
}

.quote-source-selected {
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.2);
}

.color-ask {
  background-color: #ffe4ff;
}

.color-bid {
  background-color: #d7e4ff;
}

$best-price-border: 2px solid black;

.best-ask-row {
  td:nth-child(2), td:nth-child(3) {
    border-top: $best-price-border;
  }

  td:nth-child(2) {
    border-left: $best-price-border;
  }

  td:nth-child(3) {
    border-right: $best-price-border;
  }
}

.best-bid-row {
  td:nth-child(2), td:nth-child(3) {
    border-bottom: $best-price-border !important;
  }

  td:nth-child(2) {
    border-left: $best-price-border;
  }

  td:nth-child(3) {
    border-right: $best-price-border;
  }
}
