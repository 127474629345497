$bordercolor: rgba(183, 210, 229, 0.8);

.app-accordion {
    border-color: $bordercolor;
}

h2 {
    color: #324766;
}

.accordion-hidden .accordion-header {
    border-radius: 5px;
}

.accordion-header {
    background-color: white;
    border-color: $bordercolor;

    svg {
        margin-top: auto;
        margin-bottom: auto;
    }

    span {
        color: #324766 !important;
        text-transform: none;
    }
}


.summary-info {
    display: flex;
    min-height: 110px;

    .summary-info-icon {
        width: 33.33%;
        background-color: rgb(48, 82, 122);
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .summary-info-data {
        width: 66.66%;
        background-color: #2c405a !important;
        color: #fff !important;
        padding: 15px;
    }
}
